import { Language } from 'types/src/language'

export const contentTypes = ['clip', 'gif', 'sticker'] as const
export type ContentType = (typeof contentTypes)[number]

export type Cta = {
    link: string
    text: string
}

export const ratings = ['g', 'pg', 'pg13', 'r'] as const
export type Rating = (typeof ratings)[number]

export type Metadata = Object | string
export type ExtendedMetadata = { [key: string]: Metadata }

export type LanguageTag = {
    gifId: string
    lang: Language
    text: string
}

export type Tag = {
    language?: Language
    text: string
}

export type TagCounts = { [key: string]: number }
