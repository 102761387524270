import { giphyLocalUrl } from '@/app/util/env'
import UserContext from '@/context/user-context'
import { Interface, NexaBlack } from '@/styles/fonts'
import { cn } from '@/util/cn'
import { getCookie, getPost } from '@/util/url'
import { CSRF_COOKIE_NAME } from '@/util/user'
import { useContext, useEffect, useState } from 'react'
import { useFormState } from 'react-dom'
import Button from '../button'

const REASONS_DESCRIPTION = {
    pornography: 'Pornography/Nudity',
    violence: 'Violence',
    hate: 'Hate',
    misinformation: 'Misinformation',
    general: 'General',
    clips_copyright: 'Clips Copyright',
    csam: 'CSAM',
} as const

type Reason = keyof typeof REASONS_DESCRIPTION
const REASONS_ORDER: Reason[] = [
    'pornography',
    'general',
    'violence',
    'clips_copyright',
    'hate',
    'misinformation',
    'csam',
]

type Props = {
    onClose: () => void
    gifIds: (string | number)[]
}

export const contentTakedown = (
    gifIds: (string | number)[],
    reason: string,
    additionalInfo: string,
    shouldEmailUser: boolean
) => {
    const params = {
        takedowns: gifIds.map((gifId) => ({
            reason: reason,
            gif_id: gifId,
        })),
        send_email: shouldEmailUser,
        note: additionalInfo || undefined,
    }
    return fetch(`${giphyLocalUrl}/api/v3/content-takedown/takedown`, {
        ...getPost(getCookie(CSRF_COOKIE_NAME), false),
        body: JSON.stringify(params),
    })
}

const reasonInput = 'reason'
const noteInput = 'note'
const sendEmailName = 'sendemail'

const ContentTakedown = ({ onClose, gifIds }: Props) => {
    const [isPending, setPending] = useState(false)
    const { user } = useContext(UserContext)
    const submitClicked = async (_: string, queryData: FormData) => {
        setPending(true)
        try {
            await contentTakedown(
                gifIds,
                queryData.get(reasonInput) as string,
                queryData.get(noteInput) as string,
                queryData.get(sendEmailName) === 'on'
            )
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
                return error.message
            } else {
                throw error
            }
        } finally {
            setPending(false)
        }
        return 'Success'
    }
    const [state, formAction] = useFormState(submitClicked, '')

    useEffect(() => {
        if (state === 'Success') {
            setTimeout(() => {
                location.href = `/${user?.username}`
            }, 2000)
        }
    }, [state, user?.username])

    return state === 'Success' ? (
        <div className={`flex justify-center p-20 text-center text-lg ${NexaBlack.variable}`}>
            Gif deleted...Redirecting to your user profile
        </div>
    ) : (
        <form
            className={cn(
                `group relative m-4 flex flex-col gap-2 ${Interface.variable}`,
                isPending && 'pointer-events-none opacity-80'
            )}
            action={formAction}
        >
            <h1 className="text-lg font-bold">Why are you removing the selected content?</h1>
            <div className="mb-4 grid grid-cols-[auto_auto] gap-2">
                {REASONS_ORDER.map((key) => (
                    <div className="flex gap-2" key={key}>
                        <input type="radio" id={key} value={key} name={reasonInput} required />
                        <label className="select-none" htmlFor={key}>
                            {REASONS_DESCRIPTION[key]}
                        </label>
                    </div>
                ))}
            </div>
            <h1 className="text-lg font-bold">Any additional information?</h1>
            <textarea className="my-1 h-20 w-full resize-none" name={noteInput} />
            <div className="my-4 flex gap-2">
                <input type="checkbox" name={sendEmailName} id={sendEmailName} />{' '}
                <label htmlFor={sendEmailName}>Send email to user</label>
            </div>
            <div className="flex flex-col justify-center gap-2">
                <Button
                    type="submit"
                    className="bg-gradient-primary cursor-pointer group-invalid:pointer-events-none group-invalid:opacity-60"
                >
                    Submit
                </Button>
                <Button onClick={onClose} className="bg-giphyDarkCharcoal">
                    Cancel
                </Button>
            </div>
        </form>
    )
}

export default ContentTakedown
