import { giphyUrl } from '@/app/util/env'
import { Language } from 'types/src/language'
import { POST } from './gif'

export const addLanguageTags = async (gifId: string, tags: string[], language: Language) => {
    const f = await fetch(
        `${giphyUrl}/ajax/gif/${gifId}/language-tags`,
        POST({
            lang: language.toUpperCase().replace('-', '_'),
            tags,
        })
    )

    return f.json()
}

export const removeLanguageTag = async (gifId: string, tag: string, language: Language) => {
    const f = await fetch(
        `${giphyUrl}/ajax/gif/${gifId}/language-tags?tag=${encodeURIComponent(tag)}&lang=${language
            .toUpperCase()
            .replace('-', '_')}`,
        { ...POST({}), method: 'DELETE' }
    )

    return f.json()
}
