import { useContext, useState } from 'react'
import { Language } from 'types/src/language'
import useAsyncEffect from 'utils/src/hooks/use-async-effect'
import TagPill from '../../../../controls/tag/pill'
import { Tag } from '../../../../types'
import { CONTROL_SIZE } from '../../../constants'
import { EditPanelActionContext } from '../../../contexts/action'
import { EditPanelApiContext } from '../../../contexts/api'
import { EditPanelContext } from '../../../contexts/panel'
import { EditPanelTag } from '../../../types/tag'
import { hasActionPermission } from '../../../utilities/permissions'
import { tagStringsFromGifs, tagsFromGifs, tagsFromLanguageTags } from '../../../utilities/tags'
import { useTagCounts } from '../hooks/use-tag-counts'
import { Container } from './style'

type Props = {
    className?: string
    language?: Language
}

export default ({ className, language }: Props) => {
    const { fetchLanguageTags } = useContext(EditPanelApiContext)
    const { colors, gifs, gifIds, isCompact, permissions } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)
    const [tags, setTags] = useState<EditPanelTag[]>([])
    const tagCounts = useTagCounts()
    const tagStrings = tagStringsFromGifs(gifs, permissions.staff).join(', ')
    const canFeature = hasActionPermission('feature-tag', permissions)
    const canRemove = hasActionPermission('remove-tag', permissions)

    async function refreshLanguageTags() {
        if (!language) return

        const languageTags = await fetchLanguageTags(gifIds, language)
        setTags(tagsFromLanguageTags(languageTags, gifIds))
    }

    function onFeature(tag: string, featured: boolean) {
        performAction(featured ? 'feature-tag' : 'unfeature-tag', tag)
    }

    async function onRemove(tag: string) {
        await performAction('remove-tag', {
            language,
            text: tag,
        } as Tag)

        refreshLanguageTags()
    }

    useAsyncEffect(async () => {
        if (language && language !== 'en') {
            refreshLanguageTags()
        } else {
            setTags(tagsFromGifs(gifs, permissions.staff))
        }
    }, [gifIds, language, permissions.staff, tagStrings])

    return (
        <Container $compact={isCompact} className={className}>
            {tags.map((tag, i) => (
                <TagPill
                    color={colors.control}
                    count={tagCounts[tag.text]}
                    featured={tag.featured}
                    featureable={canFeature && !language}
                    highlighted={tag.shared}
                    highlightColor={colors.sharedTag}
                    key={`edit-panel-tag-pill-${tag}-${i}`}
                    removeable={canRemove}
                    size={CONTROL_SIZE}
                    text={tag.text}
                    onFeature={(featured) => onFeature(tag.text, featured)}
                    onRemove={() => onRemove(tag.text)}
                />
            ))}
        </Container>
    )
}
