'use client'
import { cn } from '@/util/cn'
import { ButtonHTMLAttributes } from 'react'
import { useFormStatus } from 'react-dom'

type Props = ButtonHTMLAttributes<HTMLButtonElement>

const buttonClassName = 'text-giphyWhite bg-gradient-primary font-bold px-4 py-2'

export default function Button({ className, children, ...props }: Props) {
    return (
        <button className={cn(buttonClassName, className)} {...props}>
            {children}
        </button>
    )
}

export function SubmitButton({ className, children, ...props }: Props) {
    const { pending } = useFormStatus()
    return (
        <button
            type="submit"
            className={cn(buttonClassName, className, pending && `pointer-events-none bg-opacity-50`)}
            {...props}
        >
            {children}
        </button>
    )
}
