import { giphyUrl } from '@/app/util/env'
import { GET } from '@/app/util/url'
import { EditPanelGif } from 'edit/panel/types/gif'
import { ExtendedMetadata, LanguageTag, Metadata, TagCounts } from 'edit/types'
import { GifPermissions, Language } from 'types'

export type GifDetails = {
    gif: EditPanelGif
    has_attribution: boolean
    has_permissions: boolean
    permissions: GifPermissions
}

export const fetchGifDetails = async (gifId: string): Promise<GifDetails> => {
    const f = await fetch(`${giphyUrl}/ajax/gif/${gifId}/detail`, GET())
    const { result } = await f.json()

    return result as GifDetails
}

export const fetchMetadata = async (gifId: string) => {
    const f = await fetch(`${giphyUrl}/ajax/gif/${gifId}/metadata`, GET())
    const { result } = await f.json()

    return (result?.metadata || {}) as Metadata
}

export const fetchExtendedMetadata = async (gifId: string) => {
    const f = await fetch(`${giphyUrl}/ajax/gif/${gifId}/extended-metadata`, GET())
    const { result } = await f.json()

    return (result?.extended_metadata || {}) as ExtendedMetadata
}

export const fetchLanguageTags = async (gifIds: string[], language: Language) => {
    const ids = gifIds.join(',')
    const lang = language.toUpperCase().replace('-', '_')

    const url = `${giphyUrl}/ajax/gif/bulk/language-tags`
    const f = await fetch(`${url}?gif_ids=${ids}&lang=${lang}`, GET())
    const { results } = await f.json()

    return (results?.gifs || []) as LanguageTag[]
}

export const fetchTagCounts = async (gifId: string) => {
    const f = await fetch(`${giphyUrl}/ajax/gif/${gifId}/tag-counts`, GET())
    const { result } = await f.json()

    return (result?.tag_counts || {}) as TagCounts
}

export const fetchViewCount = async (gifId: string) => {
    const f = await fetch(`${giphyUrl}/api/v1/proxy-gif/${gifId}/view-count/`, GET())
    const results = await f.json()

    return (results?.viewCount || 0) as number
}
